import PropType from "prop-types";
import { Fragment } from "react";
import { useTranslate } from "../../../../language/i18n";
import { getFullDate } from "../../../../utils/DateUtil";
import Counter from "../../Counter/Counter";
import { ContentType, Item, ItemInfo, Row, RowTitle } from "../ListComponents/ListComponents";
import styles from "./Types.module.scss";

function BaseStorageMove({ list, onDelete, onQuantityChange, readOnly }) {
  const translate = useTranslate();

  const handleQuantityChange = async (item, newQuantity, product) => {
    if (newQuantity !== product.quantity) {
      onQuantityChange(item.id, { pickupList: [{ ...product, quantity: newQuantity }] });
    }
  };

  return (
    <ContentType title={translate("CART_BASE_STORAGE_MOVE_TITLE")}>
      {list.map(item => (
        <Item
          key={item.id}
          orderData={item}
          onDelete={onDelete}
          readOnly={readOnly}
          addressTitle={translate("PICKUP_ADDRESS")}
          isBaseStorageMove
        >
          <ItemInfo>
            {item.pickupList?.map(product => (
              <Fragment key={product.id}>
                <div>
                  <RowTitle text={product.name} />
                  <Row title={translate("INVENTORY_NUMBER_SHORT")} text={product?.inventoryNumber} />
                  <Row title={translate("PRODUCT_NUMBER_SHORT")} text={product?.productNumber} />
                  {item.deliveryDate && <Row title={translate("ETA")} text={getFullDate(item.deliveryDate)} />}
                  <div>
                    <Row
                      title={translate("TO_WAREHOUSE")}
                      text={`${item.toWarehouseLocation?.warehouse.value} - ${item.toWarehouseLocation?.warehouse.label}`}
                    />
                    <Row
                      title={translate("TO_LOCATION")}
                      text={`${item.toWarehouseLocation?.value} - ${item.toWarehouseLocation?.label}`}
                    />
                  </div>
                </div>

                {readOnly ? (
                  <p className={styles.quantityText}>
                    {product.quantity} {product.unit}
                  </p>
                ) : (
                  <Counter
                    onChange={quantity => handleQuantityChange(item, quantity, product)}
                    min={1}
                    max={product.maxQuantity}
                    startValue={product.quantity}
                  />
                )}
              </Fragment>
            ))}
          </ItemInfo>
        </Item>
      ))}
    </ContentType>
  );
}

BaseStorageMove.propTypes = {
  list: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      address: PropType.object,
      contentType: PropType.string,
      pickupList: PropType.arrayOf(
        PropType.shape({
          name: PropType.string,
          inventoryNumber: PropType.string,
          productNumber: PropType.string,
        }),
      ),
      text: PropType.string,
    }),
  ),
  onDelete: PropType.func,
  onQuantityChange: PropType.func,
  readOnly: PropType.bool,
};

export default BaseStorageMove;
