import PropType from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";
import { setOrder } from "../../../reducers/appData/appDataActions";
import orderService from "../../../services/order/orderService";
import { CLIENT_TYPE } from "../../../utils/constants";
import FacilitySelector from "../../shared/FacilitySelector/FacilitySelector";
import ConfirmModal from "../../shared/Modals/ConfirmModal";
import styles from "./CreateBaseStorageMoveModal.module.scss";

const CreateBaseStorageMoveModal = ({ defaultBaseStorage, visible, onCancel }) => {
  const [toBaseStorage, setToBaseStorage] = useState(defaultBaseStorage);
  const order = useSelector(state => state?.appData?.order);

  const onSubmit = async () => {
    if (order != null) return;

    const createOrder = await orderService.createOrder({
      id: toBaseStorage.id,
      type: CLIENT_TYPE.BASE_STORAGE,
    });

    if (createOrder.isOk()) {
      setOrder(createOrder.data);
    }
  };

  return (
    <ConfirmModal
      title="CREATE_BASE_STORAGE_MOVE_ORDER"
      text="CREATE_BASE_STORAGE_MOVE_DESCRIPTION"
      visible={visible}
      cancel={onCancel}
      submit={onSubmit}
      small={false}
      confirmButtonDisabled={!toBaseStorage}
    >
      <div>
        <FacilitySelector preSelected={toBaseStorage} onSelect={setToBaseStorage} customListCss={styles.list} />
      </div>
    </ConfirmModal>
  );
};

CreateBaseStorageMoveModal.propTypes = {
  defaultBaseStorage: PropType.object,
  visible: PropType.bool,
  onCancel: PropType.func,
};
export default CreateBaseStorageMoveModal;
